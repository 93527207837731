<template>
  <div v-if="form !== null" class="auto-dealer-form">
    <v-tabs background-color="white" color="deep-purple accent-4">
      <v-tab>Основная информация</v-tab>
      <v-tab-item>
        <div class="container-fluid pt-4">
          <div class="form-subheader">Сведения</div>
          <!--<b-form-group id="input-group-1" label="OID" label-for="input-1">
          <b-form-input
            id="input-1"
            :value="form.oid"
            placeholder="OID"
            disabled
          ></b-form-input>
        </b-form-group>-->

          <b-form-group id="input-group-2" label="Код марки" label-for="input-2">
            <b-form-input
              id="input-2"
              v-model="form.code_brand"
              placeholder="Код марки"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-3" label="Марка" label-for="input-3">
            <b-form-input
              id="input-3"
              v-model="form.brand"
              placeholder="Марка"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-4" label="Код модели" label-for="input-4">
            <b-form-input
              id="input-4"
              v-model="form.code_model"
              placeholder="Код модели"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-5" label="Модель" label-for="input-5">
            <b-form-input
              id="input-5"
              v-model="form.model"
              placeholder="Модель"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-5" label="Код кузова" label-for="input-5">
            <b-form-input
              id="input-5"
              v-model="form.code_body"
              placeholder="Код кузова"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-6" label="Кузов" label-for="input-6">
            <b-form-input
              id="input-6"
              v-model="form.body"
              placeholder="Кузов"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-7" label="Цвет" label-for="input-7">
            <b-form-input
              id="input-7"
              v-model="form.color"
              placeholder="Цвет"
              disabled
            ></b-form-input>
          </b-form-group>

          <div class="form-subheader">Редактируемые</div>
          <b-form>
            <b-form-group id="input-group-8" label="Фото" label-for="input-8">
              <upload-file
                id="inpit-8"
                v-model="form.photo"
                :options="logoOptions"
              />
            </b-form-group>
          </b-form>
        </div>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import UploadFile from '@/view/content/widgets/FileDrop';
import Swal from 'sweetalert2';
import { validationMixin } from 'vuelidate';
import { email, required, minLength } from 'vuelidate/lib/validators';

export default {
  components: { UploadFile },

  mixins: [validationMixin],

  props: {
    value: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      form: this.value,
      logoOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 200,
        maxFilesize: 20,
        maxFiles: 1,
        acceptedFiles: 'image/*',
      },
    };
  },

  validations: {
    form: {
      username: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(3),
      },
    },
  },

  watch: {
    form: {
      handler(v) {
        this.$emit('input', v);
      },
      deep: true,
    },
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    submit() {
      this.$v.form.$touch();
      /* if (this.$v.form.$anyError) {
        submitButton[0].classList.remove(
          'spinner',
          'spinner-light',
          'spinner-right'
        );
        return;
      } */

      Swal.fire({
        title: '',
        text: 'Сохранено!',
        icon: 'success',
        heightAuto: false,
      });
      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
